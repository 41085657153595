
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonAvatar,
    IonIcon,
    IonButton,
    IonButtons,
    IonSpinner,
    menuController,
    modalController,
    onIonViewWillLeave,
} from "@ionic/vue";
import { ref } from "vue";
import { useRouter } from "vue-router";

//import { useLocalStorage } from "@/composables/useLocalStorage"; // Importa il composable
import { useUserStore } from "@/store/userStore"; // Importa lo store

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { menu } from "ionicons/icons";
import moment from "moment";
// Generazione QR
//import QRCodeVue3 from "qrcode-vue3";

import { dbUser, dbCustomers, dbDocuments, dbRichieste, dbPresenze, dbTimbrature } from "@/services/localbase";
import apiDipendenti from "@/services/dipendenti";
import { openToast } from "@/services/toast";

import ModalAutorizzazioni from "@/components/impostazioni/ModalAutorizzazioni.vue";

export default {
    name: "Profilo",
    components: {
        IonPage,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonAvatar,
        IonIcon,
        IonButton,
        IonButtons,
        IonSpinner,
        //QRCodeVue3,
    },
    setup() {
        /* const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const userImage = `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`; */
        //const { value: user, resetValue: resetUser } = useLocalStorage(LOCALSTORAGE_KEY, {});
        const { user, clearUserStore } = useUserStore(); // Utilizza lo store
        console.log(user);
        const dipendenteID = user.dipendenti_id;
        const userImage = `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;

        const loadingQR = ref(false);

        const router = useRouter();

        const openMenu = () => {
            menuController.open("app-menu");
        };

        function changePassword() {
            console.log("test");
            router.push("/changePassword");
        }

        /**
         * Open modal to create new presenza
         */
        async function openAutorizzazioni() {
            //console.log(spesaDetail);
            const modal = await modalController.create({
                component: ModalAutorizzazioni,
            });
            return modal.present();
        }

        /**
         * Set user avatar image
         */
        function setImage(user) {
            if (user.dipendenti_foto) {
                return `${process.env.VUE_APP_BASE_URL}/uploads/${user.dipendenti_foto}`;
            } else {
                return `${process.env.VUE_APP_BASE_URL}/images/user.png`;
            }
        }

        /**
         * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
         */
        function dateFormat(date) {
            if (date && moment.isDate(new Date(date))) {
                return moment(date).format("DD/MM/YYYY");
            } else {
                return "-";
            }
        }

        /**
         * Return '-' if a customer field is null
         */
        function checkField(field) {
            if (!field || field === "") {
                return " - ";
            } else {
                return field;
            }
        }

        /**
         * ! GESTIONE APERTURA E CHIUSURA CODICE QR DA MOSTRARE COME IMMAGINE
         * ! Generazione barcode
         */

        const randomString = ref("");
        function generateRandomString() {
            const chars = "0123456789";
            let result = "";

            for (let i = 0; i < 5; i++) {
                result += chars.charAt(Math.floor(Math.random() * 5));
            }
            //console.log(result);

            const tmpArray = new Uint32Array(10);
            self.crypto.getRandomValues(tmpArray);
            result = tmpArray[5].toString().substring(0, 5);
            //console.log(result);

            return result;
        }
        generateRandomString();

        let tryIndex = 0;
        async function setBarcodeDipendente() {
            if (tryIndex < 3) {
                randomString.value = generateRandomString();
                const res = await apiDipendenti.setBarcode(dipendenteID, randomString.value);
                tryIndex++;

                //Trovato un altro dipendente con lo stesso codice gnerato, lo genero nuovamente
                if (res.status === 8) {
                    setBarcodeDipendente();
                }
            } else {
                tryIndex = 0;
                //console.log("tentativi esauriti");
                setBarcodeDipendente();
            }
        }

        /**
         * ! Mostra QR dipendente
         * * 03/06/2023 - Non genero più stringa random all'apertura ma mostro sempre lo stesso tramite chiamata a controller
         */
        const showQrImage = ref(false);

        const b64Image = ref(null);
        async function showDipendenteQr() {
            loadingQR.value = true;

            const res: any = await apiDipendenti.getQrDipendente(dipendenteID);
            if (res.data.status == 0) {
                showQrImage.value = false;
                b64Image.value = null;
                loadingQR.value = false;
                openToast(`${res.data.txt}`, "toast_danger");
                return;
            }

            showQrImage.value = true;
            b64Image.value = `data:image/png;base64,${res.data}`;
            loadingQR.value = false;
        }
        //Mostro direttament4 QR
        showDipendenteQr();

        function openQrImage() {
            //setBarcodeDipendente();
            showDipendenteQr();
            //showQrImage.value = true;
        }
        //openQrImage();

        function closeQrImage() {
            showQrImage.value = false;
        }

        /**
         * ! Force app update
         */
        function updateApp() {
            window.location.reload();
        }

        /**
         * ! Delete localbase CLIENTI and USER database
         */
        async function deleteDb() {
            /*       const deleteUser = await dbUser.collection("user").delete();
      const deleteDocuments = await dbDocuments
        .collection("documents")
        .delete();
      const deleteRichieste = await dbRichieste
        .collection("richieste")
        .delete();
      const deletePresenze = await dbPresenze.collection("presenze").delete();
      const deleteTimbrature = await dbTimbrature
        .collection("timbrature")
        .delete(); */

            dbUser.delete().then((response) => {
                console.log(response);
            });
            dbDocuments.delete().then((response) => {
                console.log(response);
            });
            dbRichieste.delete().then((response) => {
                console.log(response);
            });
            dbPresenze.delete().then((response) => {
                console.log(response);
            });
            dbTimbrature.delete().then((response) => {
                console.log(response);
            });
        }

        /**
         * Delete Localbase databases and clear localStorage
         * Redirect user to Login page
         */
        function logOut() {
            deleteDb().then(() => {
                //resetUser();
                clearUserStore();
                localStorage.clear();
                router.replace("/login");
            });
        }

        const fotoButton = ref(null);
        const inputButton = ref(null);
        const triggerCameraButton = () => {
            if (fotoButton.value) {
                inputButton.value.click();
            }
        };

        onIonViewWillLeave(() => {
            window.location.reload();
        });

        return {
            //user: user.data,
            user,
            setImage,
            userImage,
            dateFormat,
            checkField,
            logOut,
            updateApp,
            menu,
            openMenu,
            //Gestione codice QR image
            showQrImage,
            openQrImage,
            closeQrImage,
            randomString,
            b64Image,
            loadingQR,
            changePassword,
            fotoButton,
            inputButton,
            triggerCameraButton,
            openAutorizzazioni,
        };
    },
};
